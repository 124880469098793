export const countrylist= [{
      en: "Angola",
      label: "安哥拉",
      code: "+0244"
    },
    {
      en: "Afghanistan",
      label: "阿富汗",
      code: "+93"
    },
    {
      en: "Albania",
      label: "阿尔巴尼亚",
      code: "+335"
    },
    {
      en: "Algeria",
      label: "阿尔及利亚",
      code: "+213"
    },
    {
      en: "Andorra",
      label: "安道尔共和国",
      code: "+376"
    },
    {
      en: "Anguilla",
      label: "安圭拉岛",
      code: "+1254"
    },
    {
      en: "Antigua and Barbuda",
      label: "安提瓜和巴布达",
      code: "+1268"
    },
    {
      en: "Argentina",
      label: "阿根廷",
      code: "+54"
    },
    {
      en: "Armenia",
      label: "亚美尼亚",
      code: "+374"
    },
    {
      en: "Ascension",
      label: "阿森松",
      code: "+247"
    },
    {
      en: "Australia",
      label: "澳大利亚",
      code: "+61"
    },
    {
      en: "Austria",
      label: "奥地利",
      code: "+43"
    },
    {
      en: "Azerbaijan",
      label: "阿塞拜疆",
      code: "+994"
    },
    {
      en: "Bahamas",
      label: "巴哈马",
      code: "+1242"
    },
    {
      en: "Bahrain",
      label: "巴林",
      code: "+973"
    },
    {
      en: "Bangladesh",
      label: "孟加拉国",
      code: "+880"
    },
    {
      en: "Barbados",
      label: "巴巴多斯",
      code: "+1246"
    },
    {
      en: "Belarus",
      label: "白俄罗斯",
      code: "+375"
    },
    {
      en: "Belgium",
      label: "比利时",
      code: "+32"
    },
    {
      en: "Belize",
      label: "伯利兹",
      code: "+501"
    },
    {
      en: "Benin",
      label: "贝宁",
      code: "+229"
    },
    {
      en: "Bermuda Is",
      label: "百慕大群岛",
      code: "+1441"
    },
    {
      en: "Bolivia",
      label: "玻利维亚",
      code: "+591"
    },
    {
      en: "Botswana",
      label: "博茨瓦纳",
      code: "+267"
    },
    {
      en: "Brazil",
      label: "巴西",
      code: "+55"
    },
    {
      en: "Brunei",
      label: "文莱",
      code: "+673"
    },
    {
      en: "Bulgaria",
      label: "保加利亚",
      code: "+359"
    },
    {
      en: "Burkina Faso",
      label: "布基纳法索",
      code: "+226"
    },
    {
      en: "Burma",
      label: "缅甸",
      code: "+95"
    },
    {
      en: "Burundi",
      label: "布隆迪",
      code: "+257"
    },
    {
      en: "Cameroon",
      label: "喀麦隆",
      code: "+237"
    },
    {
      en: "Canada",
      label: "加拿大",
      code: "+1"
    },
    {
      en: "Cayman Is",
      label: "开曼群岛",
      code: "+1345"
    },
    {
      en: "Central African Republic",
      label: "中非共和国",
      code: "+236"
    },
    {
      en: "Chad",
      label: "乍得",
      code: "+235"
    },
    {
      en: "Chile",
      label: "智利",
      code: "+56"
    },
    {
      en: "China",
      label: "中国",
      code: "+86"
    },
    {
      en: "Colombia",
      label: "哥伦比亚",
      code: "+57"
    },
    {
      en: "Congo",
      label: "刚果",
      code: "+242"
    },
    {
      en: "Cook Is",
      label: "库克群岛",
      code: "+682"
    },
    {
      en: "Costa Rica",
      label: "哥斯达黎加",
      code: "+506"
    },
    {
      en: "Cuba",
      label: "古巴",
      code: "+53"
    },
    {
      en: "Cyprus",
      label: "塞浦路斯",
      code: "+357"
    },
    {
      en: "Czech Republic",
      label: "捷克",
      code: "+420"
    },
    {
      en: "Denmark",
      label: "丹麦",
      code: "+45"
    },
    {
      en: "Djibouti",
      label: "吉布提",
      code: "+253"
    },
    {
      en: "Dominica Rep",
      label: "多米尼加共和国",
      code: "+1890"
    },
    {
      en: "Ecuador",
      label: "厄瓜多尔",
      code: "+593"
    },
    {
      en: "Egypt",
      label: "埃及",
      code: "+20"
    },
    {
      en: "EI Salvador",
      label: "萨尔瓦多",
      code: "+503"
    },
    {
      en: "Estonia",
      label: "爱沙尼亚",
      code: "+372"
    },
    {
      en: "Ethiopia",
      label: "埃塞俄比亚",
      code: "+251"
    },
    {
      en: "Fiji",
      label: "斐济",
      code: "+679"
    },
    {
      en: "Finland",
      label: "芬兰",
      code: "+358"
    },
    {
      en: "France",
      label: "法国",
      code: "+33"
    },
    {
      en: "French Guiana",
      label: "法属圭亚那",
      code: "+594"
    },
    {
      en: "French Polynesia",
      label: "法属玻利尼西亚",
      code: "+689"
    },
    {
      en: "Gabon",
      label: "加蓬",
      code: "+241"
    },
    {
      en: "Gambia",
      label: "冈比亚",
      code: "+220"
    },
    {
      en: "Georgia",
      label: "格鲁吉亚",
      code: "+995"
    },
    {
      en: "Germany",
      label: "德国",
      code: "+49"
    },
    {
      en: "Ghana",
      label: "加纳",
      code: "+233"
    },
    {
      en: "Gibraltar",
      label: "直布罗陀",
      code: "+350"
    },
    {
      en: "Greece",
      label: "希腊",
      code: "+30"
    },
    {
      en: "Grenada",
      label: "格林纳达",
      code: "+1809"
    },
    {
      en: "Guam",
      label: "关岛",
      code: "+1671"
    },
    {
      en: "Guatemala",
      label: "危地马拉",
      code: "+502"
    },
    {
      en: "Guinea",
      label: "几内亚",
      code: "+224"
    },
    {
      en: "Guyana",
      label: "圭亚那",
      code: "+592"
    },
    {
      en: "Haiti",
      label: "海地",
      code: "+509"
    },
    {
      en: "Honduras",
      label: "洪都拉斯",
      code: "+504"
    },
    {
      en: "Hongkong",
      label: "中国香港",
      code: "+852"
    },
    {
      en: "Hungary",
      label: "匈牙利",
      code: "+36"
    },
    {
      en: "Iceland",
      label: "冰岛",
      code: "+354"
    },
    {
      en: "India",
      label: "印度",
      code: "+91"
    },
    {
      en: "Indonesia",
      label: "印度尼西亚",
      code: "+62"
    },
    {
      en: "Iran",
      label: "伊朗",
      code: "+98"
    },
    {
      en: "Iraq",
      label: "伊拉克",
      code: "+964"
    },
    {
      en: "Ireland",
      label: "爱尔兰",
      code: "+353"
    },
    {
      en: "Israel",
      label: "以色列",
      code: "+972"
    },
    {
      en: "Italy",
      label: "意大利",
      code: "+39"
    },
    {
      en: "Ivory Coast",
      label: "科特迪瓦",
      code: "+225"
    },
    {
      en: "Jamaica",
      label: "牙买加",
      code: "+1876"
    },
    {
      en: "Japan",
      label: "日本",
      code: "+81"
    },
    {
      en: "Jordan",
      label: "约旦",
      code: "+962"
    },
    {
      en: "Kampuchea (Cambodia )",
      label: "柬埔寨",
      code: "+855"
    },
    {
      en: "Kazakstan",
      label: "哈萨克斯坦",
      code: "+327"
    },
    {
      en: "Kenya",
      label: "肯尼亚",
      code: "+254"
    },
    {
      en: "Korea",
      label: "韩国",
      code: "+82"
    },
    {
      en: "Kuwait",
      label: "科威特",
      code: "+965"
    },
    {
      en: "Kyrgyzstan",
      label: "吉尔吉斯坦",
      code: "+331"
    },
    {
      en: "Laos",
      label: "老挝",
      code: "+856"
    },
    {
      en: "Latvia",
      label: "拉脱维亚",
      code: "+371"
    },
    {
      en: "Lebanon",
      label: "黎巴嫩",
      code: "+961"
    },
    {
      en: "Lesotho",
      label: "莱索托",
      code: "+266"
    },
    {
      en: "Liberia",
      label: "利比里亚",
      code: "+231"
    },
    {
      en: "Libya",
      label: "利比亚",
      code: "+218"
    },
    {
      en: "Liechtenstein",
      label: "列支敦士登",
      code: "+"
    },
    {
      en: "Lithuania",
      label: "立陶宛",
      code: "+370"
    },
    {
      en: "Luxembourg",
      label: "卢森堡",
      code: "+352"
    },
    {
      en: "Macao",
      label: "中国澳门",
      code: "+853"
    },
    {
      en: "Madagascar",
      label: "马达加斯加",
      code: "+261"
    },
    {
      en: "Malawi",
      label: "马拉维",
      code: "+265"
    },
    {
      en: "Malaysia",
      label: "马来西亚",
      code: "+60"
    },
    {
      en: "Maldives",
      label: "马尔代夫",
      code: "+960"
    },
    {
      en: "Mali",
      label: "马里",
      code: "+223"
    },
    {
      en: "Malta",
      label: "马耳他",
      code: "+356"
    },
    {
      en: "Mariana Is",
      label: "马里亚那群岛",
      code: "+1670"
    },
    {
      en: "Martinique",
      label: "马提尼克",
      code: "+596"
    },
    {
      en: "Mauritius",
      label: "毛里求斯",
      code: "+230"
    },
    {
      en: "Mexico",
      label: "墨西哥",
      code: "+52"
    },
    {
      en: "Moldova",
      label: "摩尔多瓦",
      code: "+373"
    },
    {
      en: "Monaco",
      label: "摩纳哥",
      code: "+377"
    },
    {
      en: "Mongolia",
      label: "蒙古",
      code: "+976"
    },
    {
      en: "Montserrat Is",
      label: "蒙特塞拉特岛",
      code: "+1664"
    },
    {
      en: "Morocco",
      label: "摩洛哥",
      code: "+212"
    },
    {
      en: "Mozambique",
      label: "莫桑比克",
      code: "+258"
    },
    {
      en: "Namibia",
      label: "纳米比亚",
      code: "+264"
    },
    {
      en: "Nauru",
      label: "瑙鲁",
      code: "+674"
    },
    {
      en: "Nepal",
      label: "尼泊尔",
      code: "+977"
    },
    {
      en: "Netheriands Antilles",
      label: "荷属安的列斯",
      code: "+599"
    },
    {
      en: "Netherlands",
      label: "荷兰",
      code: "+31"
    },
    {
      en: "New Zealand",
      label: "新西兰",
      code: "+64"
    },
    {
      en: "Nicaragua",
      label: "尼加拉瓜",
      code: "+505"
    },
    {
      en: "Niger",
      label: "尼日尔",
      code: "+227"
    },
    {
      en: "Nigeria",
      label: "尼日利亚",
      code: "+234"
    },
    {
      en: "North Korea",
      label: "朝鲜",
      code: "+850"
    },
    {
      en: "Norway",
      label: "挪威",
      code: "+47"
    },
    {
      en: "Oman",
      label: "阿曼",
      code: "+968"
    },
    {
      en: "Pakistan",
      label: "巴基斯坦",
      code: "+92"
    },
    {
      en: "Panama",
      label: "巴拿马",
      code: "+507"
    },
    {
      en: "Papua New Cuinea",
      label: "巴布亚新几内亚",
      code: "+675"
    },
    {
      en: "Paraguay",
      label: "巴拉圭",
      code: "+595"
    },
    {
      en: "Peru",
      label: "秘鲁",
      code: "+51"
    },
    {
      en: "Philippines",
      label: "菲律宾",
      code: "+63"
    },
    {
      en: "Poland",
      label: "波兰",
      code: "+48"
    },
    {
      en: "Portugal",
      label: "葡萄牙",
      code: "+351"
    },
    {
      en: "Puerto Rico",
      label: "波多黎各",
      code: "+1787"
    },
    {
      en: "Qatar",
      label: "卡塔尔",
      code: "+974"
    },
    {
      en: "Reunion",
      label: "留尼旺",
      code: "+262"
    },
    {
      en: "Romania",
      label: "罗马尼亚",
      code: "+40"
    },
    {
      en: "Russia",
      label: "俄罗斯",
      code: "+7"
    },
    {
      en: "Saint Lueia",
      label: "圣卢西亚",
      code: "+1758"
    },
    {
      en: "Saint Vincent",
      label: "圣文森特岛",
      code: "+1784"
    },
    {
      en: "Samoa Eastern",
      label: "东萨摩亚(美)",
      code: "+684"
    },
    {
      en: "Samoa Western",
      label: "西萨摩亚",
      code: "+685"
    },
    {
      en: "San Marino",
      label: "圣马力诺",
      code: "+378"
    },
    {
      en: "Sao Tome and Principe",
      label: "圣多美和普林西比",
      code: "+239"
    },
    {
      en: "Saudi Arabia",
      label: "沙特阿拉伯",
      code: "+966"
    },
    {
      en: "Senegal",
      label: "塞内加尔",
      code: "+221"
    },
    {
      en: "Seychelles",
      label: "塞舌尔",
      code: "+248"
    },
    {
      en: "Sierra Leone",
      label: "塞拉利昂",
      code: "+232"
    },
    {
      en: "Singapore",
      label: "新加坡",
      code: "+65"
    },
    {
      en: "Slovakia",
      label: "斯洛伐克",
      code: "+421"
    },
    {
      en: "Slovenia",
      label: "斯洛文尼亚",
      code: "+386"
    },
    {
      en: "Solomon Is",
      label: "所罗门群岛",
      code: "+677"
    },
    {
      en: "Somali",
      label: "索马里",
      code: "+252"
    },
    {
      en: "South Africa",
      label: "南非",
      code: "+27"
    },
    {
      en: "Spain",
      label: "西班牙",
      code: "+34"
    },
    {
      en: "SriLanka",
      label: "斯里兰卡",
      code: "+94"
    },
    {
      en: "St.Vincent",
      label: "圣文森特",
      code: "+1784"
    },
    {
      en: "Sudan",
      label: "苏丹",
      code: "+249"
    },
    {
      en: "Suriname",
      label: "苏里南",
      code: "+597"
    },
    {
      en: "Swaziland",
      label: "斯威士兰",
      code: "+268"
    },
    {
      en: "Sweden",
      label: "瑞典",
      code: "+46"
    },
    {
      en: "Switzerland",
      label: "瑞士",
      code: "+41"
    },
    {
      en: "Syria",
      label: "叙利亚",
      code: "+963"
    },
    {
      en: "Taiwan",
      label: "中国台湾",
      code: "+886"
    },
    {
      en: "Tajikstan",
      label: "塔吉克斯坦",
      code: "+992"
    },
    {
      en: "Tanzania",
      label: "坦桑尼亚",
      code: "+255"
    },
    {
      en: "Thailand",
      label: "泰国",
      code: "+66"
    },
    {
      en: "Togo",
      label: "多哥",
      code: "+228"
    },
    {
      en: "Tonga",
      label: "汤加",
      code: "+676"
    },
    {
      en: "Trinidad and Tobago",
      label: "特立尼达和多巴哥",
      code: "+1809"
    },
    {
      en: "Tunisia",
      label: "突尼斯",
      code: "+216"
    },
    {
      en: "Turkey",
      label: "土耳其",
      code: "+90"
    },
    {
      en: "Turkmenistan",
      label: "土库曼斯坦",
      code: "+993"
    },
    {
      en: "Uganda",
      label: "乌干达",
      code: "+256"
    },
    {
      en: "Ukraine",
      label: "乌克兰",
      code: "+380"
    },
    {
      en: "United Arab Emirates",
      label: "阿拉伯联合酋长国",
      code: "+971"
    },
    {
      en: "United Kiongdom",
      label: "英国",
      code: "+44"
    },
    {
      en: "United States of America",
      label: "美国",
      code: "+1"
    },
    {
      en: "Uruguay",
      label: "乌拉圭",
      code: "+598"
    },
    {
      en: "Uzbekistan",
      label: "乌兹别克斯坦",
      code: "+233"
    },
    {
      en: "Venezuela",
      label: "委内瑞拉",
      code: "+58"
    },
    {
      en: "Vietnam",
      label: "越南",
      code: "+84"
    },
    {
      en: "Yemen",
      label: "也门",
      code: "+967"
    },
    {
      en: "Yugoslavia",
      label: "南斯拉夫",
      code: "+381"
    },
    {
      en: "Zimbabwe",
      label: "津巴布韦",
      code: "+263"
    },
    {
      en: "Zaire",
      label: "扎伊尔",
      code: "+243"
    },
    {
      en: "Zambia",
      label: "赞比亚",
      code: "+260"
    }
  ]
