<template>
    <div class="homepage-hero-module">
        <headertop></headertop>

        <div class="video-container">
            <div :style="fixStyle" class="filter">
                <!--内容-->
            </div>
            <video :style="fixStyle" autoplay loop muted class="fillWidth" @canplay="canplay">
                <source src="../assets/video.mp4" type="video/mp4" />
                浏览器不支持 video 标签，建议升级浏览器。
            </video>
        </div>
        <div class="formBox">
            <img src="../assets/back.png" class="backImg" alt="" @click="Inv = 1" v-show="Inv != 1" />
            <div class="title">
                <img :src="logo" alt="" class="logo" />
            </div>
            <!-- 登录 -->
            <el-form ref="loginForm" :rules="loginRules" :model="loginForm" label-width="80px" v-show="Inv == 1">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="loginForm.phone" maxlength="11" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <div class="loginbtn" @click="login">登 录</div>
            </el-form>
            <!-- 注册 -->
            <el-form ref="registeredForm" :rules="registeredRules" :model="registeredForm" label-width="80px" v-show="Inv == 2">
                <el-form-item label="手机号" prop="phone">
                    <div style="display: flex; align-items: center">
                        <el-select v-model="numcode" filterable style="margin-right: 10px; width: 200px" placeholder="请选择">
                            <el-option v-for="item in guojialist" :key="item.countryCode" :label="item.countryName" :value="item.countryCode"> </el-option>
                        </el-select>
                        <el-input v-model="registeredForm.phone" maxlength="11" placeholder="请输入手机号"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <div class="code">
                        <el-input v-model="registeredForm.code" placeholder="请输入验证码"></el-input>
                        <!-- <span class="getCode" @click="getCode()">{{ codeText }}</span> -->
                        <img :src="'data:image/png;base64,' + codeImg" alt="" @click="getCodeImg()" />
                    </div>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="registeredForm.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="registeredForm.checkPass" placeholder="请输入确认密码"></el-input>
                </el-form-item>
                <!-- <el-form-item label="昵称" prop="username">
          <el-input
            v-model="registeredForm.username"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item> -->
                <div class="loginbtn" @click="registered">注 册</div>
                <div class="flex">
                    <el-checkbox v-model="checked"></el-checkbox>
                    <span @click="centerDialogVisible = true">用户注册协议</span>
                    <span @click="Inv = 1" style="position: absolute; right: 40px">返回登录</span>
                </div>
            </el-form>
            <!-- 忘记密码 -->
            <el-form ref="changPwdForm" :model="changPwdForm" :rules="changPwdFormRules" label-width="80px" v-show="Inv == 3">
                <el-form-item label="手机号" prop="phone">
                    <div style="display: flex; align-items: center">
                        <el-select v-model="numcode" filterable style="margin-right: 10px; width: 200px" placeholder="请选择">
                            <el-option v-for="item in guojialist" :key="item.countryCode" :label="item.countryName" :value="item.countryCode"> </el-option>
                        </el-select>
                        <el-input v-model="changPwdForm.phone" maxlength="11" placeholder="请输入手机号"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <el-input v-model="changPwdForm.code" placeholder="请输入验证码"></el-input>
                    <span class="getCode" @click="getChangePwdCode">{{ codeText }}</span>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input type="password" v-model="changPwdForm.newPassword" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="重复密码" prop="twonewPassword">
                    <el-input v-model="changPwdForm.twonewPassword" type="password" placeholder="请输入重复密码"></el-input>
                </el-form-item>
                <div class="loginbtn" @click="setPwd">设 置</div>
            </el-form>
            <div class="forget" v-if="Inv == 1">
                <span @click="(Inv = 2), getCodeImg()">注册</span>
                <span @click="Inv = 3, getCodeImg()">忘记密码？</span>
            </div>
        </div>
        <!-- 用户注册协议 -->
        <el-dialog title="用户注册协议" :visible.sync="centerDialogVisible" width="30%" center>
            <span v-html="agreement"></span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="centerDialogVisible = false">我已阅读</el-button>
            </span>
        </el-dialog>
        <bottombox></bottombox>
    </div>
</template>
<script>
    import { loginbyname, membersendCode, memberregisterOne, memberresetPwd, codeImg } from "@/request/api.js";

    import { inwaySetall, countryPrefixall } from "@/request/api.js";

    import bottombox from "./information/components/bottombox.vue";
    import headertop from "./information/components/header.vue";

    import { countrylist } from "../assets/country.js";
    export default {
        components: {
            bottombox,
            headertop,
        },
        data() {
            var validatePass2 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.registeredForm.password) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            return {
                guojialist: [],
                logo: "",
                numlist: countrylist,
                numcode: "",
                id: "",
                Inv: 1,
                checked: false,
                vedioCanPlay: false,
                fixStyle: "",
                loginForm: {
                    phone: "",
                    password: "",
                },
                // 13333333333
                // admin12
                loginRules: {
                    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                    password: [{ required: true, message: "请输入密码", trigger: "blur" }],
                },
                registeredForm: {
                    phone: "",
                    password: "",
                    code: "",
                    checkPass: "",
                },
                registeredRules: {
                    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                    password: [{ required: true, message: "请输入密码", trigger: "blur" }],
                    // positionCode: [{ required: true, message: "请输入邀请码", trigger: "blur" }],
                    code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
                    checkPass: [{ validator: validatePass2, trigger: "blur" }],
                },

                changPwdForm: {
                    phone: "",
                    newPassword: "",
                    code: "",
                    twonewPassword: "",
                },

                changPwdFormRules: {
                    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                    newPassword: [{ required: true, message: "请输入密码", trigger: "blur" }],
                    code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
                    twonewPassword: [{ required: true, message: "请再次输入密码", trigger: "blur" }],
                },

                codeSetting: null,
                codeText: "获取验证码",
                codeCount: 60,
                centerDialogVisible: false,
                agreement: "",
                codeImg: "",

                reg_tel: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            };
        },
        created() {
            this.defaultSet();
            if (this.$route.query.Inv) {
                this.Inv = this.$route.query.Inv;
            }
            this.getCodeImg();
        },
        mounted() {
            this.onResizeHandler();
        },
        watch: {
            centerDialogVisible(newVal, oldVal) {
                if (newVal === false && oldVal === true) {
                    this.checked = true;
                }
            },
            checked(newVal, oldVal) {},
        },
        methods: {
            onSubmit() {
                console.log("submit!");
            },
            canplay() {
                this.vedioCanPlay = true;
            },
            onResizeHandler() {
                window.onresize = () => {
                    const windowWidth = document.body.clientWidth;
                    const windowHeight = document.body.clientHeight;
                    const windowAspectRatio = windowHeight / windowWidth;
                    let videoWidth;
                    let videoHeight;
                    if (windowAspectRatio < 0.5625) {
                        videoWidth = windowWidth;
                        videoHeight = videoWidth * 0.5625;
                        this.fixStyle = {
                            height: `${windowWidth * 0.5625}px`,
                            width: `${windowWidth}px`,
                            "margin-bottom": `${(windowHeight - videoHeight) / 2}px`,
                            "margin-left": "initial",
                        };
                    } else {
                        videoHeight = windowHeight;
                        videoWidth = videoHeight / 0.5625;
                        this.fixStyle = {
                            height: `${windowHeight}px`,
                            width: `${windowHeight / 0.5625}px`,
                            "margin-left": `${(windowWidth - videoWidth) / 2}px`,
                            "margin-bottom": "initial",
                        };
                    }
                };
            },
            login() {
                this.$refs["loginForm"].validate((valid) => {
                    if (valid) {
                        loginbyname(this.loginForm).then((res) => {
                            localStorage.setItem("MemberAuthorization", res.data.token);
                            this.$notify({
                                title: "成功",
                                message: "欢迎登录",
                                type: "success",
                            });

                            this.$router.push({
                                path: "/information",
                            });
                        });
                    }
                });
            },
            registered() {
                if (!this.checked) {
                    return this.$message.error("请勾选用户注册协议");
                }
                this.$refs["registeredForm"].validate((valid) => {
                    if (valid) {
                        memberregisterOne(this.registeredForm).then((res) => {
                            this.$notify({
                                title: "成功",
                                message: "注册成功",
                                type: "success",
                            });

                            this.Inv = 1;
                            this.loginForm.phone = this.registeredForm.phone;

                            this.registeredForm = {
                                phone: "",
                                password: "",
                                code: "",
                                checkPass: "",
                            };

                        });
                    }
                });
            },
            defaultSet() {
                inwaySetall().then((res) => {
                    this.agreement = res.data.communityConvention;
                    this.logo = res.data.logo;
                    document.title = res.data.webTitle;
                    let $favicon = document.querySelector('link[rel="icon"]');
                    if ($favicon !== null) {
                        $favicon.href = res.data.webFavicon;
                    } else {
                        $favicon = document.createElement("link");
                        $favicon.rel = "icon";
                        $favicon.href = res.data.webFavicon;
                        document.head.appendChild($favicon);
                    }
                });
                countryPrefixall().then((res) => {
                    console.log("国家信息");
                    console.log(res);
                    this.guojialist = res.data;
                });
            },
            setPwd() {
                this.$refs["changPwdForm"].validate((valid) => {
                    if (valid) {
                        if (this.changPwdForm.newPassword != this.changPwdForm.twonewPassword) {
                            return this.$message.error("两次密码不一致");
                        }

                        memberresetPwd(this.changPwdForm).then((res) => {
                            this.$notify({
                                title: "成功",
                                message: "设置成功",
                                type: "success",
                            });

                            this.Inv = 1;
                        });
                    }
                });
            },
            getCodeImg() {
                codeImg().then((res) => {
                    console.log("🚀 ~ codeImg ~ res:", res);
                    this.codeImg = res.data.img;
                });
            },
            getCode() {
                if (this.registeredForm.phone == "") {
                    this.$message.warning("请输入手机号");
                    return;
                }

                if (this.codeText != "获取验证码") {
                    return;
                }

                membersendCode({
                    phone: this.registeredForm.phone,
                    countryCode: this.numcode,
                }).then((res) => {
                    console.log(res);
                    this.$message.success("短信发送成功");

                    this.codeSetting = setInterval(() => {
                        let nextCount = this.codeCount - 1;
                        if (nextCount > 0) {
                            this.codeText = "重新发送(" + nextCount + ")";
                            this.codeCount = nextCount;
                        } else {
                            clearInterval(this.codeSetting);

                            this.codeSetting = null;
                            this.codeText = "获取验证码";
                            this.codeCount = 60;
                        }
                    }, 1000);
                });
            },
            getChangePwdCode() {
                if (this.changPwdForm.phone == "") {
                    this.$message.warning("请输入手机号");
                    return;
                }

                if (this.codeText != "获取验证码") {
                    return;
                }

                membersendCode({
                    phone: this.changPwdForm.phone,
                    countryCode: this.numcode,
                }).then((res) => {
                    console.log(res);
                    this.$message.success("短信发送成功");

                    this.codeSetting = setInterval(() => {
                        let nextCount = this.codeCount - 1;

                        if (nextCount > 0) {
                            this.codeText = "重新发送(" + nextCount + ")";
                            this.codeCount = nextCount;
                        } else {
                            clearInterval(this.codeSetting);

                            this.codeSetting = null;
                            this.codeText = "获取验证码";
                            this.codeCount = 60;
                        }
                    }, 1000);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .homepage-hero-module,
    .video-container {
        position: relative;
        height: 100vh;
        overflow: hidden;
    }

    .video-container .poster img {
        z-index: 0;
        position: absolute;
    }

    .video-container .filter {
        z-index: 1;
        position: absolute;
        width: 100%;
    }

    .fillWidth {
        width: 100%;
    }

    .formBox {
        width: 500px;
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 20px 20px;
        padding-right: 40px;
        box-sizing: border-box;
        z-index: 3;

        .backImg {
            position: absolute;
            left: 20px;
            top: 20px;
            width: 30px;
            cursor: pointer;
        }

        .getCode {
            position: absolute;
            right: 20px;
            color: #e14343;
            z-index: 100;
            cursor: pointer;
        }
        .title {
            text-align: center;
            font-size: 28px;
            padding: 20px 0;
            .logo {
                width: 180px;
            }
        }

        .loginbtn {
            background: #e14343;
            color: #fff;
            height: 45px;
            font-size: 16px;
            text-align: center;
            border-radius: 5px;
            width: 50%;
            cursor: pointer;
            margin: 30px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 3px 3px #bdbdbd;
        }

        .flex {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: inline-block;
                padding-left: 10px;
                line-height: 19px;
                font-size: 14px;
                cursor: pointer;
                color: #606266;
                white-space: nowrap;
            }
        }

        .forget {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #838383;
            font-size: 14px;
            margin-left: 60px;
            cursor: pointer;
        }
    }

    .code {
        display: flex;
        align-items: center;
    }
</style>
